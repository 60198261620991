@font-face {
  font-family: iransans;
  src: url(/Fonts/IRANSansX-Regular.woff2);
  font-weight: normal;
}
@font-face {
  font-family: iransans;
  src: url(/Fonts/IRANSansX-Bold.woff2);
  font-weight: bold;
}

*,
*::before,
*::after {
  font-family: inherit;
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-family: iransans;
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  min-height: 100vh;
}

.container-top {
  padding: 1rem;
  background-color: aqua;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textarea-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 1rem;
  height: 80%;
  width: 100%;
}

.textarea {
  border: 2px solid #0f254e;
  direction: rtl;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 200px;
  margin-top: 0;
  border-radius: 9px;
  resize: none;
  padding: 1.6rem;
  outline: none;
  font-size: 1.7rem;
}

.new-box {
  min-width: 300px;
  max-width: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  width: 60%;
  background-color: #4269b1;
  margin: 0 1rem;
  border: 3px solid #0f254e;
}

.box-num {
  position: relative;
  background-color: #0f254e;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 2.5rem;
  border-radius: 6px 6px 0 0;
  padding: 1rem;
  min-height: 55px;
}

.container-bottom {
  padding: 1.5rem;
}
.boxes__heading {
  /* border-radius: 0 9px 9px 0;
  border-left: 4px #0f254e solid; */
  color: #fff;
  margin: 1rem;
  text-align: center;
  padding: 1rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #4269b1; */
}

.boxes__heading-container {
  height: 100%;
  border-right: 4px solid #0f254e;
}

.boxes {
  display: flex;
}

.btn {
  cursor: pointer;
  border-radius: 10px;
  font-weight: bold;
  font-size: 2rem;
  background-color: #afddff;
  padding: 1rem 2rem;
  margin: 1rem;
  text-decoration: none;
  border: 2px solid #222;
  transition: all 0.5s;
}

.btn-submit {
  width: 30%;
  height: 45px;
  padding: 0.1 0;
  font-size: 1.6rem;
  margin-top: 2.3rem;
}

.raise:hover,
.raise:focus {
  background-color: #193569;
  color: #fff;
  border-color: #fff;
}

.btn-edit-set {
  width: 71px;
  margin: 0;
}

.box {
  min-width: 280px;
  flex: 0 0 auto;
  display: grid;
  grid-template-rows: auto 1fr auto;
  border-radius: 10px;
  width: 30%;
  background-color: #9cdef8;
  margin: 0 1rem;
  margin: 1.2rem;
  border: 3px solid #0f254e;
}

.items {
  font-size: 2rem;
  margin-right: 3.5rem;
  direction: rtl;
  text-align: right;
  margin-top: 2rem;
}

.boxes__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: top;
}

.ss02 {
  -moz-font-feature-settings: "ss02";
  -webkit-font-feature-settings: "ss02";
  font-feature-settings: "ss02";
}

.control {
  display: flex;
}
.box__btn {
  font-weight: bold;
  width: 50%;
  border: none;
  margin-top: 2rem;
  height: 40px;
}

.btn-edit {
  background-color: orange;
  cursor: pointer;
  transition: all 0.1s;
  border-radius: 0 0 7px 0;
}

.btn-edit:hover {
  border-top: 4px solid #111;
}

.btn-remove {
  background-color: red;
  cursor: pointer;
  transition: all 0.1s;
  border-radius: 0 0 0 7px;
}

.btn-remove:hover {
  border-top: 4px solid #111;
}

.edit__container {
  text-align: center;
}

.boxes__header {
  direction: rtl;
  display: flex;
  background-color: #4269b1;
  border-radius: 9px;
  justify-content: space-between;
  border: #0f254e 4px solid;
}

.boxes__header-right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.user-icon-container {
  position: relative;
}

.user-icon {
  position: relative;
  cursor: pointer;
  width: 45px;
  margin: 1rem;
  margin-left: 2.3rem;
  margin-right: 2rem;
  margin-bottom: 0.9rem;
  border: 4px solid #9cdef881;
  border-radius: 100%;
  transition: all 0.3s;
}

.user-icon:hover {
  transform: translateY(-10px);
}

.user-icon-text {
  position: absolute;
  opacity: 0;
  bottom: -16px;
  right: -8px;
  display: inline-block;
  width: 90px;
  padding: 1rem;
  font-family: "iransans";
  font-weight: bold;
  color: #fff;
  font-size: 1.2rem;
  margin: 1rem;
  margin-top: 0;
  transition: all 0.2s;
}

form {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.form--search {
  background-color: #4269b1;
  /* border-right: 4px solid #0f254e; */
}

label {
  display: inline-block;
  font-weight: bold;
  font-size: 1.5rem;
}

.search-input {
  border-radius: 0 7px 7px 0;
  padding: 1rem;
  margin: 1rem;
  margin-left: 0;
  outline: none;
  border: none;
  transition: all 0.2s;
}

.search-input:focus {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.search-btn {
  padding: 0.8rem 2rem;
  font-weight: bold;
  background-color: #193569;
  color: #fff;
  border: 2px solid #fff;
  cursor: pointer;
  margin: 1rem;
  margin-right: 0;
  border-radius: 7px 0 0 7px;
}

.boxes__header-left {
  display: flex;
}

.boxes__header-left__btns {
  height: 100%;
  display: flex;
}

.dell-all,
.print-btn {
  font-size: 1.6rem;
  background-color: red;
  color: black;
  padding: 0.8rem 2rem;
  font-weight: bold;
  /* margin: 1rem; */
  border: none;
  border-right: #0f254e 4px solid;
  border-radius: 6px 0 0 6px;
  cursor: pointer;
  height: 100%;
}

.print-btn {
  background-color: rgb(255, 153, 0);
  color: black;
  border-radius: 0;
  /* margin: 0 1rem; */
}

.boxes__header-btn-hover {
  overflow: hidden;
  position: relative;
}
.boxes__header-btn-hover span {
  z-index: 20;
}
.boxes__header-btn-hover:after {
  background: #fff;
  content: "";
  height: 155px;
  left: -75px;
  opacity: 0.2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: 1;
}
.boxes__header-btn-hover:hover:after {
  left: 120%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.edit-textarea {
  width: 90%;
  font-size: 2rem;
  margin: 0;
}

/* Authentication Window Styles */

.close-modal {
  position: absolute;
  top: -1.2rem;
  right: 0.4rem;
  font-size: 4rem;
  color: #777;
  cursor: pointer;
  border: none;
  background: none;
}

.hidden {
  display: none;
}

.auth-window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 70%; */
  max-width: 380px;
  background-color: white;
  /* padding: 6rem; */
  padding-top: 30px;
  border-radius: 8px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.auth-window::before {
  position: absolute;
  top: 0px;
  border-radius: 5px 5px 0 0;
  left: 0;
  background-color: #193569;
  width: 100%;
  height: 30px;
  content: "";
  overflow: hidden;
}

.forms {
  position: relative;
  font-family: "iransans";
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-width: 300px;
  max-width: 300px;
  margin: 0 auto;
  /* width: 200px; */
  /* max-width: 250px; */
  height: 310px;
  /* transform: translateX(12px); */
  /* 12px */
  transition: all 0.5s;
}

.signin,
.signup {
  position: absolute;
  top: 0;
  width: 100%;
  /* position: relative; */
  direction: rtl;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  margin: 0 auto;
  /* align-items: center; */
  padding: 2rem;
  z-index: 500;
  transition: all 0.5s;
}

.signup {
  /* display: none; */
  transform: translateX(-100%);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 5;
}

.auth-switch {
  width: 100%;
  display: flex;
  font-family: "iransans";
}

.signin-form-btn {
  cursor: pointer;
  font-weight: bold;
  width: 50%;
  padding: 1rem 3rem;
  outline: none;
  border: none;
  border-left: 4px solid #193569;
  transition: all 0.2s;
  height: 50px;
  font-size: 1.8rem;
}

.signin-form-btn:hover {
  background-color: #9cdef8;
  border-bottom: #193569 4px solid;
  width: 80%;
}
.signup-form-btn:hover {
  background-color: #9cdef8;
  border-bottom: #193569 4px solid;
  width: 80%;
}

.signup-form-btn {
  cursor: pointer;
  font-weight: bold;
  width: 50%;
  outline: none;
  border: none;
  border-right: 4px solid #193569;
  transition: all 0.2s;
  height: 50px;
  font-size: 1.8rem;
}

.auth-selected {
  background-color: #9cdef8;
  border-bottom: #193569 4px solid;
  width: 80%;
}

.si-email-input,
.su-email-input,
.su-pass-input,
.si-pass-input {
  outline: none;
  border: none;
  border: 3px solid rgba(42, 129, 211, 0.5);
  border-radius: 5px;
  padding: 0.8rem 1.2rem;
  font-size: 1.5rem;
  color: #111;
  width: 250px;
  display: flex;
  align-items: center;
}

.su-pass-input,
.si-pass-input {
  padding-left: 44px;
}

.pass-input-container {
  position: relative;
}

.toggle-pass {
  cursor: pointer;
  width: 20px;
  color: rgba(9, 66, 48, 0.9);
  opacity: 0.8;
  height: auto;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-89%);
}

.curser-area {
  cursor: pointer;
  position: absolute;
  width: 39px;
  height: 45px;
  z-index: 5;
  top: 0;
  left: 0;
}

.auth__form__input {
  margin-bottom: 1.5rem;
}

.auth__form__input:focus {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.si-pass-label,
.si-email-label,
.su-pass-label,
.su-email-label {
  font-family: "iransans";
  font-weight: bold;
  padding-bottom: 0.5rem;
  padding-top: 1.7rem;
  padding-right: 0.2rem;
  font-size: 1.5rem;
  color: #111;
}

.si-email-label,
.su-email-label {
  padding-top: 0.3rem;
}

.hide {
  display: none;
}

.auth-selected {
  background-color: #9cdef8;
}

.auth-submit-btn {
  border: none;
  border: 3px solid rgba(23, 54, 44, 0.5);
  padding: 0.5rem 0.5rem;
  border-radius: 5px;
  background-color: #9cdef8;
  color: #111;
  font-weight: bold;
  font-family: "iransans";
  cursor: pointer;
  font-size: 1.6rem;
  margin-top: 1rem;
  transition: all 0.1s;
  margin-top: 2.8rem;
  width: 89px;
  height: 42px;
  margin-left: auto;
  margin-right: auto;
}

.auth-submit-btn:hover {
  background-color: #193569;
  color: #fff;
}

.forget-pass {
  color: #111;
  text-decoration: none;
  display: inline-block;
  margin-right: 0.2rem;
  font-weight: bold;
  /* height: 20px; */
  padding-right: 0.3rem;
  transition: border-right 0.1s;
  font-size: 1.2rem;
}

.forget-pass:visited {
  color: #333;
  border: none;
}

.forget-pass:focus {
  color: #193569;
}

.forget-pass:hover {
  border-right: #193569 3px solid;
  transition: border-right 0.1s;
}

/* End of Authentication Window Styles */

/* Media Queries */

@media screen and (max-width: 500px) {
  .search-input {
    width: 130px;
  }
}
@media screen and (max-width: 388px) {
  .search-input {
    width: 100px;
  }
}
@media screen and (max-width: 355px) {
  .boxes__header {
    flex-direction: column;
  }
}

@media screen and (max-width: 728px) {
  .boxes__header {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .boxes__header-right {
    justify-content: right;
    border-bottom: 4px solid #0f254e;
  }

  .boxes__header-left {
    justify-content: space-between;
  }

  .boxes__header-left__btns {
    height: 100%;
  }

  .dell-all {
    border-radius: 0 0 0 6px;
    min-height: 70px;
  }

  .print-btn {
    min-height: 70px;
  }

  .word-now-wrap {
    word-wrap: normal;
  }
}

@media screen and (max-width: 523px) {
  .dell-all,
  .print-btn {
    font-size: 1.4rem;
  }

  .search-input {
    max-width: 150px;
  }
}

@media screen and (max-width: 456px) {
  .boxes__header-left {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }

  .boxes__header-left__btns {
    width: 100%;
  }

  .dell-all,
  .print-btn {
    width: 50%;
  }

  .print-btn {
    border-radius: 0 0 6px 0;
    border-right: none;
  }

  .form {
    width: 100%;
  }

  .search-input {
    min-width: 70%;
    width: 100%;
  }

  .search-btn {
    width: 90px;
  }
}

@media screen and (max-width: 350px) {
  .search-input {
    min-width: 150px;
  }
}

@media screen and (max-width: 333px) {
  .forms {
    min-width: 270px;
  }
}

@media screen and (max-width: 311px) {
  .new-box {
    min-width: 250px;
  }

  .search-input {
    min-width: 50px;
  }
}
@media screen and (max-width: 287px) {
  .new-box {
    min-width: 220px;
  }

  .forms {
    min-width: 260px;
  }
}

.printClone {
  display: none;
  max-width: 210px;
}

.finalPrint {
  display: block;
  position: absolute;
  z-index: -10;
  opacity: 0;
  top: 0;
  width: 0;
  /* height: 0; */
  overflow: hidden;
}

@media print {
  header,
  .container-bottom *:not(.finalPrint):not(.finalPrint *) {
    visibility: hidden;
  }

  .finalPrint {
    width: auto;
    display: block;
    opacity: 100;
    position: absolute;
    top: 0;
    left: 92px;
  }

  .box {
    display: inline-grid;
  }

  .control {
    display: none;
    visibility: collapse;
  }

  .items {
    padding-bottom: 2rem;
  }

  .box-num-edit-img {
    visibility: hidden;
    display: none;
  }

  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 0;
  }
}

/* End of Media Queries */

/* Toggle */
.toggle {
  cursor: pointer;
  display: inline-block;
  margin-top: 1rem;
}

.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 58px;
  height: 32px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}
.toggle-switch:before,
.toggle-switch:after {
  content: "";
}
.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.toggle-checkbox:checked + .toggle-switch {
  background: #56c080;
}
.toggle-checkbox:checked + .toggle-switch:before {
  left: 30px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.box-naming-input-container {
  direction: rtl;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 60px; */
}

.box-num-edit-img {
  cursor: pointer;
  position: absolute;
  top: 23%;
  left: 20px;
  width: 30px;
  border-color: #9cdef8;
  color: #9cdef8;
  background-color: #9cdef8;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 7px;
}

.box-naming-input {
  width: 60%;
  height: 50px;
}

.box-naming-btn {
  height: 50px;
}
