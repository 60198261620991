@font-face {
  font-family: iransans;
  src: url("IRANSansX-Regular.e19f67c6.woff2");
  font-weight: normal;
}

@font-face {
  font-family: iransans;
  src: url("IRANSansX-Bold.0824b05b.woff2");
  font-weight: bold;
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  font-family: inherit;
}

html {
  box-sizing: border-box;
  font-family: iransans;
  font-size: 62.5%;
}

body {
  min-height: 100vh;
}

.container-top {
  background-color: #0ff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.textarea-container {
  text-align: center;
  height: 80%;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 1rem;
  display: flex;
}

.textarea {
  direction: rtl;
  width: 90%;
  height: 200px;
  resize: none;
  border: 2px solid #0f254e;
  border-radius: 9px;
  outline: none;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 1.6rem;
  font-size: 1.7rem;
}

.new-box {
  min-width: 300px;
  max-width: 500px;
  width: 60%;
  background-color: #4269b1;
  border: 3px solid #0f254e;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
  display: flex;
  position: relative;
}

.box-num {
  color: #fff;
  width: 100%;
  text-align: center;
  min-height: 55px;
  background-color: #0f254e;
  border-radius: 6px 6px 0 0;
  padding: 1rem;
  font-size: 2.5rem;
  font-weight: bold;
  position: relative;
}

.container-bottom {
  padding: 1.5rem;
}

.boxes__heading {
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  padding: 1rem;
  font-size: 2rem;
  display: flex;
}

.boxes__heading-container {
  height: 100%;
  border-right: 4px solid #0f254e;
}

.boxes {
  display: flex;
}

.btn {
  cursor: pointer;
  background-color: #afddff;
  border: 2px solid #222;
  border-radius: 10px;
  margin: 1rem;
  padding: 1rem 2rem;
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
  transition: all .5s;
}

.btn-submit {
  width: 30%;
  height: 45px;
  margin-top: 2.3rem;
  padding: .1px 0;
  font-size: 1.6rem;
}

.raise:hover, .raise:focus {
  color: #fff;
  background-color: #193569;
  border-color: #fff;
}

.btn-edit-set {
  width: 71px;
  margin: 0;
}

.box {
  min-width: 280px;
  width: 30%;
  background-color: #9cdef8;
  border: 3px solid #0f254e;
  border-radius: 10px;
  flex: none;
  grid-template-rows: auto 1fr auto;
  margin: 1.2rem;
  display: grid;
}

.items {
  direction: rtl;
  text-align: right;
  margin-top: 2rem;
  margin-right: 3.5rem;
  font-size: 2rem;
}

.boxes__container {
  justify-content: center;
  align-items: top;
  flex-wrap: wrap;
  display: flex;
}

.ss02 {
  -moz-font-feature-settings: "ss02";
  -webkit-font-feature-settings: "ss02";
  font-feature-settings: "ss02";
}

.control {
  display: flex;
}

.box__btn {
  width: 50%;
  height: 40px;
  border: none;
  margin-top: 2rem;
  font-weight: bold;
}

.btn-edit {
  cursor: pointer;
  background-color: orange;
  border-radius: 0 0 7px;
  transition: all .1s;
}

.btn-edit:hover {
  border-top: 4px solid #111;
}

.btn-remove {
  cursor: pointer;
  background-color: red;
  border-radius: 0 0 0 7px;
  transition: all .1s;
}

.btn-remove:hover {
  border-top: 4px solid #111;
}

.edit__container {
  text-align: center;
}

.boxes__header {
  direction: rtl;
  background-color: #4269b1;
  border: 4px solid #0f254e;
  border-radius: 9px;
  justify-content: space-between;
  display: flex;
}

.boxes__header-right {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.user-icon-container {
  position: relative;
}

.user-icon {
  cursor: pointer;
  width: 45px;
  border: 4px solid #9cdef881;
  border-radius: 100%;
  margin: 1rem 2rem .9rem 2.3rem;
  transition: all .3s;
  position: relative;
}

.user-icon:hover {
  transform: translateY(-10px);
}

.user-icon-text {
  opacity: 0;
  width: 90px;
  color: #fff;
  margin: 0 1rem 1rem;
  padding: 1rem;
  font-family: iransans;
  font-size: 1.2rem;
  font-weight: bold;
  transition: all .2s;
  display: inline-block;
  position: absolute;
  bottom: -16px;
  right: -8px;
}

form {
  justify-content: center;
  display: flex;
}

.form--search {
  background-color: #4269b1;
}

label {
  font-size: 1.5rem;
  font-weight: bold;
  display: inline-block;
}

.search-input {
  border: none;
  border-radius: 0 7px 7px 0;
  outline: none;
  margin: 1rem 1rem 1rem 0;
  padding: 1rem;
  transition: all .2s;
}

.search-input:focus {
  box-shadow: 0 5px 15px #00000059;
}

.search-btn {
  color: #fff;
  cursor: pointer;
  background-color: #193569;
  border: 2px solid #fff;
  border-radius: 7px 0 0 7px;
  margin: 1rem 0 1rem 1rem;
  padding: .8rem 2rem;
  font-weight: bold;
}

.boxes__header-left {
  display: flex;
}

.boxes__header-left__btns {
  height: 100%;
  display: flex;
}

.dell-all, .print-btn {
  color: #000;
  cursor: pointer;
  height: 100%;
  background-color: red;
  border: none;
  border-right: 4px solid #0f254e;
  border-radius: 6px 0 0 6px;
  padding: .8rem 2rem;
  font-size: 1.6rem;
  font-weight: bold;
}

.print-btn {
  color: #000;
  background-color: #f90;
  border-radius: 0;
}

.boxes__header-btn-hover {
  position: relative;
  overflow: hidden;
}

.boxes__header-btn-hover span {
  z-index: 20;
}

.boxes__header-btn-hover:after {
  content: "";
  height: 155px;
  opacity: .2;
  width: 50px;
  z-index: 1;
  background: #fff;
  transition: all .55s cubic-bezier(.19, 1, .22, 1);
  position: absolute;
  top: -50px;
  left: -75px;
  transform: rotate(35deg);
}

.boxes__header-btn-hover:hover:after {
  transition: all .55s cubic-bezier(.19, 1, .22, 1);
  left: 120%;
}

.edit-textarea {
  width: 90%;
  margin: 0;
  font-size: 2rem;
}

.close-modal {
  color: #777;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 4rem;
  position: absolute;
  top: -1.2rem;
  right: .4rem;
}

.hidden {
  display: none;
}

.auth-window {
  max-width: 380px;
  z-index: 10;
  background-color: #fff;
  border-radius: 8px;
  padding-top: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 3rem 5rem #0000004d;
}

.auth-window:before {
  width: 100%;
  height: 30px;
  content: "";
  background-color: #193569;
  border-radius: 5px 5px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.forms {
  min-width: 300px;
  max-width: 300px;
  height: 310px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-family: iransans;
  transition: all .5s;
  display: flex;
  position: relative;
  overflow: hidden;
}

.signin, .signup {
  width: 100%;
  direction: rtl;
  width: auto;
  z-index: 500;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 2rem;
  transition: all .5s;
  display: flex;
  position: absolute;
  top: 0;
}

.signup {
  transform: translateX(-100%);
}

.overlay {
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(3px);
  z-index: 5;
  background-color: #0009;
  position: fixed;
  top: 0;
  left: 0;
}

.auth-switch {
  width: 100%;
  font-family: iransans;
  display: flex;
}

.signin-form-btn {
  cursor: pointer;
  width: 50%;
  height: 50px;
  border: none;
  border-left: 4px solid #193569;
  outline: none;
  padding: 1rem 3rem;
  font-size: 1.8rem;
  font-weight: bold;
  transition: all .2s;
}

.signin-form-btn:hover, .signup-form-btn:hover {
  width: 80%;
  background-color: #9cdef8;
  border-bottom: 4px solid #193569;
}

.signup-form-btn {
  cursor: pointer;
  width: 50%;
  height: 50px;
  border: none;
  border-right: 4px solid #193569;
  outline: none;
  font-size: 1.8rem;
  font-weight: bold;
  transition: all .2s;
}

.auth-selected {
  width: 80%;
  background-color: #9cdef8;
  border-bottom: 4px solid #193569;
}

.si-email-input, .su-email-input, .su-pass-input, .si-pass-input {
  color: #111;
  width: 250px;
  border: 3px solid #2a81d380;
  border-radius: 5px;
  outline: none;
  align-items: center;
  padding: .8rem 1.2rem;
  font-size: 1.5rem;
  display: flex;
}

.su-pass-input, .si-pass-input {
  padding-left: 44px;
}

.pass-input-container {
  position: relative;
}

.toggle-pass {
  cursor: pointer;
  width: 20px;
  color: #094230e6;
  opacity: .8;
  height: auto;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-89%);
}

.curser-area {
  cursor: pointer;
  width: 39px;
  height: 45px;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
}

.auth__form__input {
  margin-bottom: 1.5rem;
}

.auth__form__input:focus {
  box-shadow: 0 5px 15px #00000059;
}

.si-pass-label, .si-email-label, .su-pass-label, .su-email-label {
  color: #111;
  padding-top: 1.7rem;
  padding-bottom: .5rem;
  padding-right: .2rem;
  font-family: iransans;
  font-size: 1.5rem;
  font-weight: bold;
}

.si-email-label, .su-email-label {
  padding-top: .3rem;
}

.hide {
  display: none;
}

.auth-selected {
  background-color: #9cdef8;
}

.auth-submit-btn {
  color: #111;
  cursor: pointer;
  width: 89px;
  height: 42px;
  background-color: #9cdef8;
  border: 3px solid #17362c80;
  border-radius: 5px;
  margin-top: 2.8rem;
  margin-left: auto;
  margin-right: auto;
  padding: .5rem;
  font-family: iransans;
  font-size: 1.6rem;
  font-weight: bold;
  transition: all .1s;
}

.auth-submit-btn:hover {
  color: #fff;
  background-color: #193569;
}

.forget-pass {
  color: #111;
  margin-right: .2rem;
  padding-right: .3rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  transition: border-right .1s;
  display: inline-block;
}

.forget-pass:visited {
  color: #333;
  border: none;
}

.forget-pass:focus {
  color: #193569;
}

.forget-pass:hover {
  border-right: 3px solid #193569;
  transition: border-right .1s;
}

@media screen and (max-width: 500px) {
  .search-input {
    width: 130px;
  }
}

@media screen and (max-width: 388px) {
  .search-input {
    width: 100px;
  }
}

@media screen and (max-width: 355px) {
  .boxes__header {
    flex-direction: column;
  }
}

@media screen and (max-width: 728px) {
  .boxes__header {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .boxes__header-right {
    border-bottom: 4px solid #0f254e;
    justify-content: right;
  }

  .boxes__header-left {
    justify-content: space-between;
  }

  .boxes__header-left__btns {
    height: 100%;
  }

  .dell-all {
    min-height: 70px;
    border-radius: 0 0 0 6px;
  }

  .print-btn {
    min-height: 70px;
  }

  .word-now-wrap {
    word-wrap: normal;
  }
}

@media screen and (max-width: 523px) {
  .dell-all, .print-btn {
    font-size: 1.4rem;
  }

  .search-input {
    max-width: 150px;
  }
}

@media screen and (max-width: 456px) {
  .boxes__header-left {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    display: grid;
  }

  .boxes__header-left__btns {
    width: 100%;
  }

  .dell-all, .print-btn {
    width: 50%;
  }

  .print-btn {
    border-right: none;
    border-radius: 0 0 6px;
  }

  .form {
    width: 100%;
  }

  .search-input {
    min-width: 70%;
    width: 100%;
  }

  .search-btn {
    width: 90px;
  }
}

@media screen and (max-width: 350px) {
  .search-input {
    min-width: 150px;
  }
}

@media screen and (max-width: 333px) {
  .forms {
    min-width: 270px;
  }
}

@media screen and (max-width: 311px) {
  .new-box {
    min-width: 250px;
  }

  .search-input {
    min-width: 50px;
  }
}

@media screen and (max-width: 287px) {
  .new-box {
    min-width: 220px;
  }

  .forms {
    min-width: 260px;
  }
}

.printClone {
  max-width: 210px;
  display: none;
}

.finalPrint {
  z-index: -10;
  opacity: 0;
  width: 0;
  display: block;
  position: absolute;
  top: 0;
  overflow: hidden;
}

@media print {
  header, .container-bottom :not(.finalPrint):not(.finalPrint *) {
    visibility: hidden;
  }

  .finalPrint {
    width: auto;
    opacity: 100;
    display: block;
    position: absolute;
    top: 0;
    left: 92px;
  }

  .box {
    display: inline-grid;
  }

  .control {
    visibility: collapse;
    display: none;
  }

  .items {
    padding-bottom: 2rem;
  }

  .box-num-edit-img {
    visibility: hidden;
    display: none;
  }

  @page {
    size: A4;
    margin: 0;
  }
}

.toggle {
  cursor: pointer;
  margin-top: 1rem;
  display: inline-block;
}

.toggle-switch {
  width: 58px;
  height: 32px;
  vertical-align: middle;
  background: #ccc;
  border-radius: 16px;
  transition: background .25s;
  display: inline-block;
  position: relative;
}

.toggle-switch:before, .toggle-switch:after {
  content: "";
}

.toggle-switch:before {
  width: 24px;
  height: 24px;
  background: linear-gradient(#fff 0%, #eee 100%);
  border-radius: 50%;
  transition: left .25s;
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  box-shadow: 0 0 0 1px #00000040;
}

.toggle:hover .toggle-switch:before {
  background: linear-gradient(#fff 0% 100%);
  box-shadow: 0 0 0 1px #00000080;
}

.toggle-checkbox:checked + .toggle-switch {
  background: #56c080;
}

.toggle-checkbox:checked + .toggle-switch:before {
  left: 30px;
}

.toggle-checkbox {
  visibility: hidden;
  position: absolute;
}

.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.box-naming-input-container {
  direction: rtl;
  justify-content: center;
  align-items: center;
  display: flex;
}

.box-num-edit-img {
  cursor: pointer;
  width: 30px;
  color: #9cdef8;
  background-color: #9cdef8;
  border-color: #9cdef8;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  display: none;
  position: absolute;
  top: 23%;
  left: 20px;
}

.box-naming-input {
  width: 60%;
  height: 50px;
}

.box-naming-btn {
  height: 50px;
}

/*# sourceMappingURL=index.5a839ff2.css.map */
